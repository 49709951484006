
.form-holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-height: 100vh;
}

.form-holder .form-content {
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}

.form-content .form-items {
    border: 3px solid #fff;
    padding: 40px;
    display: inline-block;
    width: 90vw;
    max-width: 550px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    text-align: left;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.form-content h3 {
    color: #fff;
    text-align: left;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 5px;
}

.form-content h3.form-title {
    margin-bottom: 30px;
}

.form-content p {
    color: #fff;
    text-align: left;
    font-size: 17px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 30px;
}


.form-content textarea {
   width: 100%;
   border-radius: 10px;
    padding: 10px;
}

.form-content textarea:hover, .form-content textarea:focus {
    border: 0;
    background-color: #ebeff8;
    color: #8D8D8D;
}

.mv-up{
    margin-top: -9px !important;
    margin-bottom: 8px !important;
}

.inputtag{
    width: 100%;
    padding: 10px;
    border-radius: 10px;
}
.fullw{
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.btnpost{
    font-size: 16px;
    padding: 5px;
    border-radius: 5px;
    border: none;
    padding-left: 15px;
    padding-right: 15px;
    background: #288b00;
    color: white;
    cursor: pointer;
}

.paren{
    display: flex;
    align-items: center;
    justify-content: center;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    height: 100vh;
}

.paren2{
    --tw-bg-opacity: 1;
    background-color: rgb(40 42 54 / var(--tw-bg-opacity));
    padding: 1.25rem;
    border-radius: 0.75rem;
    width: 400px;
    max-width: 90vw;
    text-align: center;
}

.centerdiv{
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 700;
}

.flexdiv{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.inputtag{
    width: 100%;
    padding: 10px;
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity));
    font-weight: 700;
    outline: 2px solid transparent;
    outline-offset: 2px;
    margin-top: 0.5rem/* 8px */;
    margin-bottom: 0.5rem/* 8px */;
    border-color: transparent;
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgb(238 238 238 / var(--tw-bg-opacity));
    border-radius: 0.375rem
}
.inputtag:hover{
    --tw-border-opacity: 1;
    border-color: rgb(30 58 138 / var(--tw-border-opacity));
}
.signinbtn{
    --tw-bg-opacity: 1;
    background-color: rgb(74 238 136 / var(--tw-bg-opacity));
    width: 100%;
    margin-top: 0.5rem/* 8px */;
    margin-bottom: 0.5rem/* 8px */;
    border-radius: 0.375rem/* 6px */;
    align-items: center;
    display: flex;
    height: 2.5rem/* 40px */;
    justify-content: center;
}

.signinbtn:hover{
    --tw-bg-opacity: 1;
    background-color: rgb(80 207 129 / var(--tw-bg-opacity));
}
.bgdiv{
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}